<template>
  <v-sheet class="container-view">
    <LoadingView v-show="!isShow" />
    <v-fade-transition>
      <v-container class="pt-8 pb-12" v-if="isShow">
        <Header />
        <v-row justify="center" class="mt-16" v-if="isShowNotFound">
          <div class="fuwa-semi-title">記事が見つかりませんでした</div>
        </v-row>
        <v-row justify="start" class="mt-4" v-if="article">
          <v-col cols="12">
            <div class="d-flex flex-row">
              <!-- image -->
              <v-img
                class="radius20 mr-2"
                height="4rem"
                width="4rem"
                style="max-width: 4rem"
                v-bind:src="article.image_url"
                v-bind:v-lazy="article.image_url"
              ></v-img>
              <!-- title -->
              <div class="article-title align-self-center">
                {{ article.title }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-4" v-if="article">
          <v-col xl="12" md="12" sm="12" xs="12" class="pa-0">
            <!-- type -->
            <v-chip
              small
              class="article-chip article-chip-type mt-1 ml-3"
              :to="'/article-list?type_id=' + article.type_id"
            >
              {{ article.type_name }}
            </v-chip>
            <!-- tags -->
            <v-chip
              class="article-chip mt-1 mx-1"
              v-for="tag in article.tag"
              v-bind:key="tag.id"
              v-bind:color="tag.background_color"
              outlined
              small
              :to="'/article-list?tag_id=' + tag.id"
            >
              {{ tag.name }}
            </v-chip>
            <!-- created_at -->
            <p class="article-date text-right mx-4 mt-3">
              作成: {{ article.created_at | moment }} &nbsp; 更新:
              {{ article.updated_at | moment }}
            </p>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          class="mt-4 mb-4 article-top-ad-row"
          v-if="article"
        >
          <v-col align="center" cols="12">
            <Adsense
              v-if="isProduction"
              data-ad-client="ca-pub-5268422005221882"
              data-ad-slot="4581794834"
              data-full-width-responsive="true"
            >
            </Adsense>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-4" v-if="article">
          <v-col md="9" sm="12" xs="12" class="pa-0">
            <!-- preview -->
            <div class="article-preview">
              <div v-html="compiledMarkdown"></div>
            </div>
            <div class="social_tipping_view" align="center">
              <p class="social_tipping_text menlo">
                参考になりましたら下記ボタンから応援をお願いします、励みになります。
              </p>
              <v-col>
                <v-btn
                  depressed
                  class="social_tipping_bmc_button mr-2 mt-2 mb-2"
                  :href="bmcUrl"
                >
                  <IconBmc class="mr-1" />
                  <div class="text-main">buy me a coffee</div>
                </v-btn>
                <v-btn
                  depressed
                  class="social_tipping_twitter_button mt-2 mb-2"
                  :href="twitterShareUrl"
                >
                  <IconTwitter class="white-color mr-1" />
                  <div class="white-color">Twitterシェア</div>
                </v-btn>
              </v-col>
            </div>
          </v-col>
          <!-- PC用 目次 -->
          <v-col md="3" sm="0" xs="0" class="pa-0 article-toc-col">
            <TocView :toc="toc" />
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
    <!-- SP用 目次ボタン -->
    <TocDrawerButton
      :isShowDrawer="isShowDrawer"
      @toggle="isShowDrawer = !isShowDrawer"
    />
    <!-- SP用 目次 -->
    <TocDrawer
      :toc="toc"
      :isShowDrawer="isShowDrawer"
      @swipeRightTocDrawer="isShowDrawer = false"
    />
  </v-sheet>
</template>

<script async>
import Marked from "marked";
import Axios from "../repository/Axios";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import "highlight.js/styles/agate.css";
import Highlightjs from "highlight.js/lib/core";
import Constants from "../packs/constants";
import IconTwitter from "../svg/twitter.svg";
import IconBmc from "../svg/bmc.svg";
const Header = () => import("../fuwa-component/article/article-header.vue");
const TocView = () => import("../fuwa-component/article/toc-view.vue");
const TocDrawer = () => import("../fuwa-component/article/toc-drawer.vue");
const TocDrawerButton = () =>
  import("../fuwa-component/article/toc-drawer-button.vue");
const LoadingView = () => import("../fuwa-component/loading-view.vue");

export default {
  components: {
    Header,
    TocView,
    TocDrawer,
    TocDrawerButton,
    LoadingView,
    IconTwitter,
    IconBmc,
  },
  data: function () {
    return {
      isShow: false,
      isShowDrawer: false,
      isShowNotFound: false,
      article: null,
      toc: [],
      isProduction: process.env.NODE_ENV === "production",
    };
  },
  created: function () {
    // Markedで目次(TOC)を抜き出し
    const renderer = new Marked.Renderer();
    renderer.heading = (text, level) => {
      const slug = encodeURI(text.toLowerCase());
      this.toc.push({
        level: level,
        slug: slug,
        title: text,
      });
      return (
        "<h" + level + ' id="' + slug + '">' + text + "</h" + level + ">\n"
      );
    };
    // MarkedのOptionを設定
    Marked.setOptions({
      langPrefix: "",
      smartLists: true,
      smartypants: true,
      breaks: true, // 改行を正しく適用してくれる
      gfm: true,
      renderer: renderer,
      highlight: (code, lang) => {
        // langは `kotlin=` のようにイコールを付けてはいけない。 `kotlin` にすること。
        // TODO: イコールが付いていても適用できるように実装
        return Highlightjs.highlightAuto(code, [lang]).value;
      },
    });
  },
  computed: {
    compiledMarkdown: function () {
      const text = this.article.text.replace(
        /https?:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g,
        function (newStr) {
          // URLはencodeされている場合を考慮して、decodeする
          return decodeURI(newStr);
        }
      );
      return Marked(text);
    },
    bmcUrl() {
      return Constants.BMC_URL;
    },
    twitterShareUrl() {
      return (
        Constants.TWITTER_SHARE_URL +
        "?text=" +
        this.article.title +
        "&url=" +
        Constants.FUWAMAKI_HOME_URL +
        this.$route.fullPath
      );
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch: async function () {
      try {
        const { data } = await Axios.fetchArticle(this.$route.params.id);
        this.article = data.article;
        this.isShow = true;
        if (process.env.NODE_ENV === "production") {
          this.$gtag.event("load_article_" + this.$route.params.id, {
            event_category: "article",
            event_label: this.$route.params.id,
          });
        }
      } catch {
        this.isShow = true;
        this.isShowNotFound = true;
      }
    },
  },
  filters: {
    moment(date) {
      return dayjs(date).format("YYYY/MM/DD");
    },
  },
};
</script>
