<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container v-if="isShow" class="py-8">
        <div class="fuwa-title mt-2 mb-2">謎解きの部屋</div>
        <div class="description-text mb-2">
          謎解きを無料で楽しめるお部屋がこちらです。
        </div>
      </v-container>
    </v-fade-transition>
  </v-sheet>
</template>

<script async>
export default {
  components: {},
  data: function () {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.isShow = true;
  },
};
</script>
