<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container v-if="isShow" class="py-8">
        <div class="fuwa-title mt-2 mb-2">偏差値計算ツール</div>
        <div class="description-text mb-2">
          点数一覧を入力することで、標準偏差・偏差値一覧を自動で出力します。
        </div>
        <div class="supplement-text mb-2">
          ※入力したデータは、サーバに保存されたり他の用途で利用されたりすることはありません。<br />
          このページを開いた状態で、ご使用の端末の通信をOFFにしても、計算は問題なく行われます。
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <!-- input -->
            <h3 class="fuwa-semi-title mt-2 mb-1">入力欄</h3>
            <v-textarea
              filled
              label="Excelで縦に並ぶ点数一覧をコピーしてここにペースト"
              :rows="10"
              v-model="text"
            ></v-textarea>
            <!-- button -->
            <v-btn block class="common-button mb-8" v-on:click="calculate">
              計算
            </v-btn>
            <!-- 平均 card -->
            <v-card outlined tile class="my-2">
              <v-card-title> 平均 </v-card-title>
              <v-card-text class="deviation-number">
                {{ average }}
              </v-card-text>
            </v-card>
            <!-- 標準偏差 card -->
            <v-card outlined tile class="my-2">
              <v-card-title> 標準偏差 </v-card-title>
              <v-card-text class="deviation-number">
                {{ standardDeviation }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <!-- 偏差値枠 -->
            <h3 class="fuwa-semi-title mt-2 mb-1">偏差値</h3>
            <v-card outlined tile class="wrap" min-height="304">
              <v-card-text class="newline deviation-number">{{
                deviationArray
              }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- 補足 -->
        <Supplement />
        <!-- 使い方 -->
        <HowTo />
      </v-container>
    </v-fade-transition>
  </v-sheet>
</template>

<script async>
const Supplement = () =>
  import("../../fuwa-component/deviation/deviation-supplement.vue");
const HowTo = () =>
  import("../../fuwa-component/deviation/deviation-how-to.vue");

export default {
  components: {
    Supplement,
    HowTo,
  },
  data: function () {
    return {
      isShow: false,
      text: "",
      average: 0,
      standardDeviation: 0,
      deviationArray: "",
    };
  },
  mounted() {
    this.isShow = true;
  },
  methods: {
    calculate: function () {
      // textareaをintの配列に
      const intArray = this.text.split("\n").flatMap((x) => Number(x));
      var sumProvider = function (arr, fn) {
        if (fn) {
          return sumProvider(arr.map(fn));
        } else {
          return arr.reduce(function (prev, current, i, arr) {
            return prev + current;
          });
        }
      };
      var averageProvider = function (arr, fn) {
        return sumProvider(arr, fn) / arr.length;
      };
      // 平均値を計算
      const averageValue = averageProvider(intArray);
      this.average = averageValue.toFixed(2);
      // (点数-平均値) の配列
      const remainderArray = intArray.flatMap((x) => x - averageValue);
      // (点数-平均値)^2 の配列
      const remainderSquareArray = remainderArray.flatMap((x) =>
        Math.pow(x, 2)
      );
      // 標準偏差 (点数-平均値)^2の平均の平方根
      const standardDeviationValue = Math.sqrt(
        averageProvider(remainderSquareArray)
      );
      this.standardDeviation = standardDeviationValue.toFixed(2);
      // 偏差値 ((点数-平均値)*10/標準偏差)+50 の配列
      this.deviationArray = remainderArray
        .flatMap((x) => (x * 10) / standardDeviationValue + 50)
        .flatMap((x) => x.toFixed(2))
        .join("\n");
    },
  },
};
</script>
