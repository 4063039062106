import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"container-view"},[_c(VFadeTransition,[(_vm.isShow)?_c(VContainer,{staticClass:"py-6"},[_c('div',{staticClass:"fuwa-title mt-2 mb-2"},[_vm._v("通知ほしい（Want Notice） FAQ")]),_vm._v(" "),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,[_c('div',{staticClass:"white-background text-main radius13 word-break my-2 pa-6"},[_vm._v("\n            実際にお問い合わせ頂いた内容を中心に、FAQとしてこちらに記載します。\n            "),_c('br'),_vm._v("\n            まず、お問い合わせ頂いた方に感謝を申し上げます。\n            "),_c('br'),_vm._v(" "),_c('br'),_vm._v(" "),_c('h3',[_vm._v("実現できていない機能について")]),_vm._v(" "),_c('br'),_vm._v("\n            以下に記す機能はAppleシステムの仕様上、本アプリで実現することができていません。\n            "),_c('br'),_c('br'),_vm._v(" "),_c('ul',[_c('li',[_vm._v("毎月の月末指定の定期通知")]),_vm._v(" "),_c('li',[_vm._v("\n                「（今から）分ごと」「（今から）日・時間ごと」の開始時間指定\n              ")])]),_vm._v(" "),_c('br'),_vm._v("\n            Appleシステムのアップデートにより実現できるようになりましたら、すぐに対応し本アプリをアップデート致します。\n            "),_c('br'),_vm._v("\n            ユーザーの方にはご迷惑をおかけしますが、何卒ご理解頂けますと幸いです。\n          ")])])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }