<template>
  <div id="home">
    <v-sheet class="container-view">
      <LoadingView v-show="isLoading" />
      <v-container class="home-container pb-12">
        <v-row class="mt-2">
          <v-col cols="12" md="8" class="home-new-container">
            <!-- 新着 -->
            <v-slide-x-transition>
              <HomeNew :articles="newArticles" v-if="newArticles.length" />
            </v-slide-x-transition>
          </v-col>
          <!-- fuwamaki mediaの説明 -->
          <v-col cols="12" md="4">
            <v-scroll-y-transition>
              <div v-if="isShowDescription">
                <div class="home-description">
                  <div class="home-description-title">{{ whatIsSite }}</div>
                  {{ whatIsDescription }}
                </div>
                <v-card outlined class="home-news">
                  2023/6/21 freelance hubのメディアで紹介して頂きました！ ※<a :href="freelanceHubCaseUrl" class="text-secondary-color">freelance hub案件一覧</a>  <br />
                  <a :href="freelanceHub01Url" class="text-secondary-color">
                    自動化や効率化に活用しよう！AIツールの活用例・体験談まとめ
                  </a>
                </v-card>
              </div>
            </v-scroll-y-transition>
            <Adsense
              v-if="isProduction"
              data-ad-client="ca-pub-5268422005221882"
              data-ad-slot="5204881212"
              data-full-width-responsive="true"
            >
            </Adsense>
          </v-col>
        </v-row>
        <!-- タイプ別記事 -->
        <v-scroll-y-reverse-transition>
          <div class="mt-16" v-if="otherArticles.length">
            <div class="fuwa-title mt-16 mb-2">タイプ別記事</div>
            <v-row>
              <v-col cols="12" md="4">
                <HomeType
                  :articles="technologyArticles"
                  :type="articleTypes[0]"
                />
              </v-col>
              <v-col cols="12" md="4">
                <HomeType :articles="serviceArticles" :type="articleTypes[1]" />
              </v-col>
              <!-- エンタメは消して、その他に変更した -->
              <v-col cols="12" md="4">
                <HomeType :articles="otherArticles" :type="articleTypes[3]" />
              </v-col>
            </v-row>
          </div>
        </v-scroll-y-reverse-transition>
        <!-- fuwamaki プロダクト -->
        <v-slide-x-transition>
          <div v-if="articleTypes.length" class="mt-16 mb-8">
            <div class="fuwa-title mt-16 mb-2">fuwamaki プロダクト</div>
            <div class="horizontal-scroll-menu">
              <div
                v-for="homeProduct in homeProducts"
                v-bind:key="homeProduct.id"
                class="horizontal-scroll-content no-padding"
              >
                <HomeProductCard :homeProduct="homeProduct" />
              </div>
            </div>
          </div>
        </v-slide-x-transition>
      </v-container>
    </v-sheet>
  </div>
</template>

<script async>
import Axios from "../repository/Axios";
import Constants from "../packs/constants";
const HomeNew = () => import("../fuwa-component/home/home-new.vue");
const HomeType = () => import("../fuwa-component/home/home-type.vue");
const HomeTypeOther = () =>
  import("../fuwa-component/home/home-type-other.vue");
const HomeProductCard = () =>
  import("../fuwa-component/home/home-product-card.vue");
const LoadingView = () => import("../fuwa-component/loading-view.vue");

export default {
  components: {
    HomeNew,
    HomeType,
    HomeTypeOther,
    HomeProductCard,
    LoadingView,
  },
  data: function () {
    return {
      isLoading: true,
      isShowDescription: false,
      isProduction: process.env.NODE_ENV === "production",
      newArticles: [],
      technologyArticles: [],
      serviceArticles: [],
      otherArticles: [],
      articleTypes: [],
      tab: null,
      homeProducts: Constants.HOME_PRODUCTS,
      whatIsSite: Constants.WHAT_IS_SITE,
      whatIsDescription: Constants.WHAT_IS_SITE_DESCRIPTION,
      freelanceHub01Url: Constants.FREELANCE_HUB_01_URL,
      freelanceHubCaseUrl: Constants.FREELANCE_HUB_CASE_URL,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch: async function () {
      const { data } = await Axios.fetchHome(this.$root);
      this.isLoading = false;
      this.newArticles = data.new_articles;
      this.isShowDescription = true;
      this.technologyArticles = data.technology_articles;
      this.serviceArticles = data.service_articles;
      this.otherArticles = data.other_articles;
      this.articleTypes = data.article_types;
    },
  },
};
</script>
