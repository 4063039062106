<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container class="py-6" v-if="isShow">
        <div class="fuwa-title mt-2 mb-2">通知ほしい（Want Notice） FAQ</div>
        <v-row justify="center">
          <v-col>
            <div
              class="white-background text-main radius13 word-break my-2 pa-6"
            >
              実際にお問い合わせ頂いた内容を中心に、FAQとしてこちらに記載します。
              <br />
              まず、お問い合わせ頂いた方に感謝を申し上げます。
              <br />
              <br />
              <h3>実現できていない機能について</h3>
              <br />
              以下に記す機能はAppleシステムの仕様上、本アプリで実現することができていません。
              <br /><br />
              <ul>
                <li>毎月の月末指定の定期通知</li>
                <li>
                  「（今から）分ごと」「（今から）日・時間ごと」の開始時間指定
                </li>
              </ul>
              <br />
              Appleシステムのアップデートにより実現できるようになりましたら、すぐに対応し本アプリをアップデート致します。
              <br />
              ユーザーの方にはご迷惑をおかけしますが、何卒ご理解頂けますと幸いです。
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
  </v-sheet>
</template>

<script async>
export default {
  data: function () {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.isShow = true;
  },
};
</script>
