import Vue from "vue";
import Vuetify from "vuetify/lib";
import App from "../app.vue";
import router from "./routes";
import Router from "vue-router";
import VueGtag from "vue-gtag";
import VueLazyload from "vue-lazyload";
import hljs from "highlight.js/lib/core";
import javascript from "highlight.js/lib/languages/javascript";
import typescript from "highlight.js/lib/languages/typescript";
import swift from "highlight.js/lib/languages/swift";
import kotlin from "highlight.js/lib/languages/kotlin";
import bash from "highlight.js/lib/languages/bash";
import css from "highlight.js/lib/languages/css";
import xml from "highlight.js/lib/languages/xml";
import yaml from "highlight.js/lib/languages/yaml";
import json from "highlight.js/lib/languages/json";
import ruby from "highlight.js/lib/languages/ruby";
import sql from "highlight.js/lib/languages/sql";
import markdown from "highlight.js/lib/languages/markdown";
import php from "highlight.js/lib/languages/php";
import java from "highlight.js/lib/languages/java";
import groovy from "highlight.js/lib/languages/groovy";
import python from "highlight.js/lib/languages/python";
import dart from "highlight.js/lib/languages/dart";
import Ads from "vue-google-adsense";

hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("typescript", typescript);
hljs.registerLanguage("swift", swift);
hljs.registerLanguage("kotlin", kotlin);
hljs.registerLanguage("bash", bash);
hljs.registerLanguage("css", css);
hljs.registerLanguage("xml", xml);
hljs.registerLanguage("yaml", yaml);
hljs.registerLanguage("json", json);
hljs.registerLanguage("ruby", ruby);
hljs.registerLanguage("sql", sql);
hljs.registerLanguage("markdown", markdown);
hljs.registerLanguage("php", php);
hljs.registerLanguage("java", java);
hljs.registerLanguage("groovy", groovy);
hljs.registerLanguage("python", python);
hljs.registerLanguage("dart", dart);

Vue.use(Router);
Vue.use(Vuetify);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});
Vue.use(require("vue-script2"));
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);

if (process.env.NODE_ENV === "production") {
  Vue.use(
    VueGtag,
    {
      config: { id: "G-96QN8SDYQV" },
    },
    router
  );
}

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    vuetify: new Vuetify(),
    router,
    render: (h) => h(App),
    data: {
      // どのjsクラスからも利用できるインスタンス変数
      homeFetchData: null,
      articleListFetchData: null,
    },
  }).$mount();
  document.body.appendChild(app.$el);
});
