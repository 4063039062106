<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container class="py-6" v-if="isShow">
        <div class="fuwa-title mt-2 mb-2">
          通知ほしい（Want Notice） プライバシーポリシー
        </div>
        <v-row justify="center">
          <v-col>
            <div
              class="white-background text-main radius13 word-break my-2 pa-6"
            >
              <h3>このアプリについて</h3>
              <br />
              当アプリは、個人が運営している「通知管理アプリ」です。<br />
              <br />
              <h4>当アプリの内容</h4>
              <br />
              ユーザーがほしい通知を登録し、その通知を受け取ることができます。<br />
              <br />
              <h4>アプリ内課金について</h4>
              <br />
              基本無料です。<br />
              <br />
              <h4>アプリに関して</h4>
              <br />
              当アプリでは、第三者配信の広告サービス（AdMob）を利用しています。<br />
              Google
              の提供する広告設定により、インタレストベース広告が表示されないようにオプトアウトいただくことが可能です。<br />
              Admob
              のプライバシポリシーとオプトアウトについては下記をご参照ください<br />
              ・<a
                href="https://support.google.com/admob/answer/6128543?hl=ja&ref_topic=2745287"
              >
                プライバシーポリシー
              </a>
              <br />
              ・<a href="https://support.google.com/ads/answer/2662922?hl=ja">
                オプトアウト
              </a>
              <br />
              <br />
              <h4>Firebase に関して</h4>
              <br />
              当アプリは、以下の目的でFirebaseを使用しています。<br />
              <br />
              ・ログイン認証機能<br />
              ・データベースツール<br />
              ・クラッシュレポート<br />
              <br />
              アプリ開発者とFirebaseがご利用者の情報及びアプリ内で操作したデータの取得・保存をする場合がございます。<br />
              アプリ開発者はこれらの個人情報を厳重に取り扱い、当アプリの利用目的以外での使用は一切いたしません。<br />
              Firebaseの個々の機能と取得したデータの取り扱いについては、以下のリンクをご確認ください。<br />
              <br />
              ・<a href="https://firebase.google.com/"
                >https://firebase.google.com/</a
              ><br />
              ・<a href="https://firebase.google.com/terms/?hl=ja"
                >https://firebase.google.com/terms/?hl=ja</a
              ><br />
              ・<a href="https://firebase.google.com/docs/auth/?hl=ja"
                >https://firebase.google.com/docs/auth/?hl=ja</a
              ><br />
              ・<a href="https://firebase.google.com/docs/firestore?hl=ja"
                >https://firebase.google.com/docs/firestore?hl=ja</a
              ><br />
              <br />
              <h4>アプリの廃止・停止</h4>
              <br />
              このアプリは開発者の都合によりいつでも当アプリを廃止できるものとします。その際はプッシュ通知、ニュース部分での告知とします。<br />
              <br />
              <h4>禁止事項</h4>
              <br />
              本サービスに不適切な内容、不都合があった場合、開発者はいつでも改変・削除ができるものとします。<br />
              <br />
              <h4>個人情報の取り扱いについて</h4>
              <br />
              当アプリでは以下の種類の情報を収集および受信します。<br />
              利用目的としては当アプリの提供・改善、お問い合わせ対応、個人を特定できない統計情報としての利用になります。<br />
              <br />
              ・ユーザーID: ユーザーを識別するためのID。<br />
              ・メールアドレス: ログインしたユーザーのメールアドレス。<br />
              ・利用データ:
              当アプリをユーザーがどのようにアクセス・利用したかを把握するためのデータ。<br />
              ・コンテンツ: ユーザーが設定した、ほしい通知の情報。<br />
              <br />
              <br />
              <h4>個人情報の提供について</h4>
              <br />
              当アプリではユーザーのアカウントのプライバシー保護に細心の注意を払っています。<br />
              事前の承諾なく個人情報を第三者に提供することはありません。<br />
              令状、裁判所命令、召喚令状、またはその他の合法的な政府の要請を遵守ために必要であると判断した場合を除き、<br />
              ユーザの情報を法執行機関またはその他の政府機関に開示することはありません。<br />
              稀ではありますが、人、財産、および弊社が本サービスを運営しているシステムに対する<br />
              違法行為、詐欺行為疑惑、脅迫の恐れに関して調査、対処するために必要と考えられる場合、<br />
              またはその他法的義務を遵守するために必要であると判断された場合は、ユーザの情報を共有する可能性があります。<br />
              <br />
              <h4>個人情報の開示・訂正・利用停止・消去について</h4>
              <br />
              当アプリは、本人が自己の個人情報について、開示・訂正・利用停止・消去等を求める権利を有していることを確認し、<br />
              これらの要求ある場合には、異議なく対応します。ただし、個人を直接に特定することができない個人情報についてはこの限りではありません。<br />
              <br />
              <h4>未成年の個人情報について</h4>
              <br />
              未成年者が、当アプリを使用する場合は、必ず親権者の同意を得るものとし、当アプリを使用した時点で、本ポリシーに対して親権者の同意があるものとみなします。<br />
              <br />
              <h4>プライバシーポリシーの更新について</h4>
              <br />
              当アプリは、プライバシーポリシーを変更する場合があります。<br />
              プライバシーポリシーに重要な変更がある場合には、アプリ上で告知します。<br />
              本ページを都度ご確認の上、開発者のプライバシーポリシーをご理解いただくようお願いします。<br />
              <br />
              <h4>データ解析</h4>
              <br />
              アプリの利便性向上のため、匿名で、個人を特定できない範囲に細心の注意を払い、アクセス解析をしております。<br />
              例えば、アプリがクラッシュした時、どの部分でクラッシュしたかを匿名で送信し、バグの素早い修正に役立たせております。<br />
              また、デバイスやアプリバージョンの使用率、特定の機能の使用率などを解析し、アプリの改善に役立てています。<br />
              例えば、使われていない機能はシンプルにするため省くなど、記入したパスワードに関する情報は送信いたしません。<br />
              <br />
              <h4>免責事項</h4>
              <br />
              アプリの安全性の向上、不都合に対するサポートなどに対しては可能な限り力をいれておりますが、<br />
              利用者が当アプリを利用して生じた損害に関して開発元は責任を負わないものとします。<br />
              <br />
              docomo.ne.jpやezweb,softbankのメールアドレスにはPC（パソコン）から返信できないためその他のメールアドレスからお願い致します。<br />
              ※全てのメールにご返信できない旨何卒よろしくお願いします。
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
  </v-sheet>
</template>

<script async>
export default {
  data: function () {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.isShow = true;
  },
};
</script>
