<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container v-if="isShow" class="py-8">
        <div class="fuwa-title mt-2 mb-2">謎解きの部屋</div>
        <div class="fuwa-semi-title mt-2 mb-2">第一問</div>
        <v-row class="mt-4" justify="center">
          <v-img
            class="question-max-width center"
            src="https://i.imgur.com/uVkSsvd.jpg"
            lazy-src="https://i.imgur.com/uVkSsvd.jpg"
          ></v-img>
        </v-row>
        <v-row justify="center" v-if="isCorrectShow" class="mt-8">
          <div class="question-judge-text">正解</div>
        </v-row>
        <v-row justify="center" class="mt-12">
          <div class="question-button-max-width">
            <v-text-field
              label="答えを入力"
              v-model="inputText"
              class="border-secondary"
              flat
              solo
              hide-details
            ></v-text-field>
          </div>
        </v-row>
        <v-row justify="center" class="mt-8">
          <v-btn
            class="common-button question-button-max-width"
            height="40px"
            v-on:click="judge"
          >
            送信
          </v-btn>
        </v-row>
      </v-container>
    </v-fade-transition>
    <v-snackbar
      :timeout="2000"
      v-model="isShowErrorBar"
      color="#ef4565"
      rounded="pill"
    >
      不正解です
    </v-snackbar>
  </v-sheet>
</template>

<script async>
export default {
  components: {},
  data: function () {
    return {
      isShow: false,
      isCorrectShow: false,
      isShowErrorBar: false,
      inputText: "",
    };
  },
  mounted() {
    this.isShow = true;
  },
  methods: {
    judge: function () {
      this.inputText == "かぎ" ? this.correct() : this.incorrect();
    },
    correct: function () {
      this.isCorrectShow = true;
    },
    incorrect: function () {
      this.isShowErrorBar = true;
    },
  },
};
</script>
