<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container v-if="isShow" class="pb-12">
        <Header />
        <Career />
        <Skill />
        <Footer />
      </v-container>
    </v-fade-transition>
  </v-sheet>
</template>

<script async>
const Header = () => import("../fuwa-component/profile/profile-header.vue");
const Career = () => import("../fuwa-component/profile/profile-career.vue");
const Skill = () => import("../fuwa-component/profile/profile-skill.vue");
const Footer = () => import("../fuwa-component/profile/profile-footer.vue");

export default {
  components: {
    Header,
    Career,
    Skill,
    Footer,
  },
  data: () => ({
    isShow: false,
  }),
  mounted() {
    this.isShow = true;
  },
};
</script>
