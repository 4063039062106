export default {
  FUWAMAKI_HOME_URL: "https://www.fuwamaki.com",
  BMC_URL: "https://www.buymeacoffee.com/fuwamaki24",
  TWITTER_SHARE_URL: "https://twitter.com/intent/tweet",
  TWITTER_URL: "https://twitter.com/fuwamaki24",
  GITHUB_URL: "https://github.com/fuwamaki",
  QIITA_URL: "https://qiita.com/fuwamaki",
  FREELANCE_HUB_01_URL: "https://freelance-hub.jp/column/detail/362/",
  FREELANCE_HUB_CASE_URL: "https://freelance-hub.jp/project/",
  HEADER_TOKEN: "Token uzZbw3jz2KsbPwaZvrdH2CTR",
  WHAT_IS_SITE: "fuwamaki mediaとは",
  WHAT_IS_SITE_DESCRIPTION:
    "現役エンジニアが発信する情報サイト。技術系の記事を中心に、注目のアプリ・サービス紹介、おすすめのマンガ・アニメなど様々な記事を掲載しています。",
  HOME_PRODUCTS: [
    {
      id: 0,
      image_url:
        "https://fuwamaki-blog-backet.s3.ap-northeast-1.amazonaws.com/uploads/public/simple-maze-icon.png",
      title: "シンプルめいろ",
      description: "スタートからゴールまで、なぞって遊ぶ迷路。正解を判定してくれます。",
      destination: "APPSTOREページ",
      href: "https://apps.apple.com/app/id6471811918",
      to: "",
    },
    {
      id: 1,
      image_url:
        "https://fuwamaki-blog-backet.s3.ap-northeast-1.amazonaws.com/uploads/public/1024x1024.png",
      title: "通知ほしい",
      description: "通知がほしい!と思ったらこのアプリ。ほしい通知を設定しておけば、忘れても大丈夫。",
      destination: "APPSTOREページ",
      href: "https://apps.apple.com/app/id1606057879",
      to: "",
    },
    {
      id: 2,
      image_url:
        "https://fuwamaki-blog-backet.s3-ap-northeast-1.amazonaws.com/uploads/public/shake-judge-icon.png",
      title: "Shake Judge",
      description: "振るとYes/Noの判定音が出るアプリ。色や音のカスタマイズもできます。",
      destination: "APPSTOREページ",
      href: "https://apps.apple.com/app/id1543329989",
      to: "",
    },
    {
      id: 3,
      image_url: "",
      title: "偏差値計算ツール",
      description:
        "点数一覧を入力することで、標準偏差・偏差値一覧を自動で出力してくれる、Webツール。",
      destination: "本サイトページ",
      href: "",
      to: "/deviation",
    },
  ],
  PRODUCTS: [
    {
      id: 0,
      image_url:
        "https://fuwamaki-blog-backet.s3.ap-northeast-1.amazonaws.com/uploads/public/simple-maze-icon.png",
      title: "シンプルめいろ",
      description: "スタートからゴールまで、なぞって遊ぶ迷路。正解を判定してくれます。",
      app_store_url: "https://apps.apple.com/app/id6471811918",
      google_play_url: "",
      privacy_policy_to: "./privacy-policy/simple-maze",
      faq_to: "",
      un_released: false,
    },
    {
      id: 1,
      image_url:
        "https://fuwamaki-blog-backet.s3.ap-northeast-1.amazonaws.com/uploads/public/1024x1024.png",
      title: "通知ほしい",
      description:
        "すぐ忘れてしまう!通知がほしい!と思ったらこのアプリ。ほしい通知を設定しておけば、忘れても大丈夫。",
      app_store_url: "https://apps.apple.com/app/id1606057879",
      google_play_url: "",
      privacy_policy_to: "./privacy-policy/want-notice",
      faq_to: "./faq/want-notice",
      un_released: false,
    },
    {
      id: 2,
      image_url:
        "https://fuwamaki-blog-backet.s3-ap-northeast-1.amazonaws.com/uploads/public/shake-judge-icon.png",
      title: "Shake Judge",
      description: "振るとYes/Noの判定音が出るアプリ。色や音のカスタマイズもできます。",
      app_store_url: "https://apps.apple.com/app/id1543329989",
      google_play_url: "",
      privacy_policy_to: "./privacy-policy/shake-judge",
      faq_to: "",
      un_released: false,
    },
    {
      id: 3,
      image_url:
        "https://fuwamaki-blog-backet.s3-ap-northeast-1.amazonaws.com/uploads/public/last-diary-icon.png",
      title: "シンプル日記",
      description: "Markdownを使ってシンプルに日記を書くことができるアプリ。",
      app_store_url: "",
      google_play_url: "",
      privacy_policy_to: "",
      // privacy_policy_to: "./privacy-policy/last-diary",
      faq_to: "",
      un_released: true,
    },
    {
      id: 4,
      image_url:
        "https://fuwamaki-blog-backet.s3-ap-northeast-1.amazonaws.com/uploads/public/pre-school-visit-icon.png",
      title: "保育園見学",
      description: "保育園見学のメモ用アプリ。",
      app_store_url: "",
      google_play_url: "",
      privacy_policy_to: "",
      // privacy_policy_to: "./privacy-policy/pre-school-visit",
      faq_to: "",
      un_released: true,
    },
    {
      id: 5,
      image_url:
        "https://fuwamaki-blog-backet.s3.ap-northeast-1.amazonaws.com/uploads/public/story-timer-icon.png",
      title: "Story Timer",
      description: "自由にストーリーのように時間を設定できるアプリ。",
      app_store_url: "",
      google_play_url: "",
      privacy_policy_to: "",
      privacy_policy_to: "./privacy-policy/story-timer-app",
      faq_to: "",
      un_released: true,
    },
  ],
  PRODUCT_TOOLS: [
    {
      id: 100,
      title: "偏差値計算ツール",
      description:
        "点数一覧を入力することで、標準偏差・偏差値一覧を自動で出力してくれる、Webツール。",
      to: "/deviation",
    },
  ],
  SITES: [
    {
      id: 1000,
      title: "田村学習塾",
      description:
        "友人が運営する学習塾。HPの作成をお手伝いさせていただきました。2023年3月に閉校。",
      href: "",
      image_url: "",
    },
  ],
};
