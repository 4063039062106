Object.defineProperty(String.prototype, "katakanaToHiragana", {
  value: function katakanaToHiragana() {
    return this.replace(/[\u30a1-\u30f6]/g, function (match) {
      var chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  },
  writable: true,
  configurable: true,
});

Object.defineProperty(String.prototype, "hiraganaToKatagana", {
  value: function hiraganaToKatagana() {
    return this.replace(/[\u3041-\u3096]/g, function (match) {
      var chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  },
  writable: true,
  configurable: true,
});
