import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"container-view"},[_c(VFadeTransition,[(_vm.isShow)?_c(VContainer,{staticClass:"py-8"},[_c('div',{staticClass:"fuwa-title mt-2 mb-2"},[_vm._v("謎解きの部屋")]),_vm._v(" "),_c('div',{staticClass:"fuwa-semi-title mt-2 mb-2"},[_vm._v("第一問")]),_vm._v(" "),_c(VRow,{staticClass:"mt-4",attrs:{"justify":"center"}},[_c(VImg,{staticClass:"question-max-width center",attrs:{"src":"https://i.imgur.com/uVkSsvd.jpg","lazy-src":"https://i.imgur.com/uVkSsvd.jpg"}})],1),_vm._v(" "),(_vm.isCorrectShow)?_c(VRow,{staticClass:"mt-8",attrs:{"justify":"center"}},[_c('div',{staticClass:"question-judge-text"},[_vm._v("正解")])]):_vm._e(),_vm._v(" "),_c(VRow,{staticClass:"mt-12",attrs:{"justify":"center"}},[_c('div',{staticClass:"question-button-max-width"},[_c(VTextField,{staticClass:"border-secondary",attrs:{"label":"答えを入力","flat":"","solo":"","hide-details":""},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}})],1)]),_vm._v(" "),_c(VRow,{staticClass:"mt-8",attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"common-button question-button-max-width",attrs:{"height":"40px"},on:{"click":_vm.judge}},[_vm._v("\n          送信\n        ")])],1)],1):_vm._e()],1),_vm._v(" "),_c(VSnackbar,{attrs:{"timeout":2000,"color":"#ef4565","rounded":"pill"},model:{value:(_vm.isShowErrorBar),callback:function ($$v) {_vm.isShowErrorBar=$$v},expression:"isShowErrorBar"}},[_vm._v("\n    不正解です\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }