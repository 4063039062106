<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container class="py-6" v-if="isShow">
        <div class="fuwa-title mt-2 mb-2">
          シンプルめいろ プライバシーポリシー
        </div>
        <v-row justify="center">
          <v-col>
            <div
              class="white-background text-main radius13 word-break my-2 pa-6"
            >
              <h3>このアプリについて</h3>
              <br />
              本アプリは、個人が運営している「なぞって遊ぶ迷路アプリ」です。<br />
              <br />
              <h4>本アプリの内容</h4>
              <br />
              なぞって遊ぶ迷路アプリです。子供向けに作成しておりますが、大人も楽しめる内容になっています。<br />
              お客様の情報は端末内でのみ保存されるので、他のユーザに共有されることはありません。<br />
              <br />
              <h4>アプリ内課金について</h4>
              <br />
              基本無料です。<br />
              <br />
              <h4>個人情報とセキュリティについて</h4>
              <br />
              アンケート等でユーザーの家族構成などを収集しています。これに加え商品情報も蓄積され他のユーザー及び第三者への情報へ提供する場合があります。<br />
              その際の主な利用用途はマーケティングへの活用、他のアプリとの連携など。<br />
              ただし、メールアドレスや個人を特定する情報は外部への提供は一切行いません。（ユーザーが任意で設定したカテゴリー名、メモや備考の情報も一切外部へ提供しません。）<br />
              <br />
              <h4>Firebase に関して</h4>
              <br />
              当アプリでは、クラッシュレポートのために Firebase
              を使用しています。Firebase については下記 URL
              をご参照ください。<br />
              ・<a href="https://firebase.google.com/"
                >https://firebase.google.com/</a
              ><br />
              ・<a href="https://firebase.google.com/terms/?hl=ja"
                >https://firebase.google.com/terms/?hl=ja</a
              ><br />
              <br />
              <h4>アプリの廃止・停止</h4>
              <br />
              このアプリは開発者の都合によりいつでも本アプリを廃止できるものとします。その際はプッシュ通知、ニュース部分での告知とします。<br />
              <br />
              <h4>禁止事項</h4>
              <br />
              本サービスに不適切な内容、不都合があった場合、開発者はいつでも改変・削除ができるものとします。<br />
              <br />
              <h4>1．個人情報の取り扱いについて</h4>
              <br />
              個人情報とは、個人に関する情報であり、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む。）を指します。<br />
              このアプリでは開発者への送信は一切行いません。お客さまの端末で全て完結する様な仕様となっております。よって開発者はお客様の個人情報は一切持たない設計となります。<br />
              <br />
              <h4>2．個人情報の提供について</h4>
              <br />
              アプリ内に収集した個人情報を外部に提供する事は一切ございません。<br />
              <br />
              <h4>3．プライバシーポリシーの更新について</h4>
              <br />
              当アプリは、プライバシーポリシーを変更する場合があります。
              プライバシーポリシーに重要な変更がある場合には、アプリ上で告知します。
              本ページを都度ご確認の上、開発者のプライバシーポリシーをご理解いただくようお願いします。<br />
              <br />
              <h4>4．データ解析</h4>
              <br />
              アプリの利便性向上のため、匿名で、個人を特定できない範囲に細
              心の注意を払い、アクセス解析をしております。例えば、アプリが
              クラッシュした時、どの部分でクラッシュしたかを匿名で送信し、
              バグの素早い修正に役立たせております。
              また、デバイスやアプリバージョンの使用率、特定の機能の使用率
              などを解析し、アプリの改善に役立てています。例えば、使われて
              いない機能はシンプルにするため省くなど)
              記入したパスワードに関する情報は送信いたしません。<br />
              <br />
              <h4>5．免責事項</h4>
              <br />
              アプリの安全性の向上、不都合に対するサポートなどに対しては可能な限り力をいれておりますが、利用者が本アプリを利用して生じた損害に関して開発元は責任を負わないものとします。<br />
              <br />
              個人情報のお取扱いについて 【利用目的】<br />
              お客様からいただく個人情報は、お問い合わせ・ご質問への回答、情報提供のために使用させていただきます。
              お問い合わせの内容によっては、電子メール以外の方法で回答を差し上げる場合がございます。
              ご回答までに数日要する場合や、ご質問によってはお応えできかねる場合もございます。あらかじめご了承ください。
              当方からの回答は、お客様個人に当てたものです。一部・全部の転用や二次利用はご遠慮ください。
              <br />
              docomo.ne.jpやezweb,softbankのメールアドレスにはPC（パソコン）から返信できないためその他のメールアドレスからお願い致します。<br />
              ※全てのメールにご返信できない旨何卒よろしくお願いします。
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-fade-transition>
  </v-sheet>
</template>

<script async>
export default {
  data: function () {
    return {
      isShow: false,
    };
  },
  mounted() {
    this.isShow = true;
  },
};
</script>
