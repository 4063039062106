<template>
  <div id="search">
    <v-sheet class="container-view">
      <v-fade-transition>
        <v-container class="py-8" v-if="isShow">
          <div class="fuwa-title mt-2 mb-2">
            {{ selectedArticleTypeName != null ? selectedArticleTypeName : "" }}
            記事一覧
            {{ articleCount != null ? articleCount : "" }}
          </div>
          <!-- 絞り込み -->
          <div class="narrow-view radius4 mt-4">
            <v-row>
              <v-col cols="12">
                <div class="narrow-search-title mb-1">絞り込み</div>
                <v-text-field
                  v-model="searchText"
                  class="border-secondary"
                  @input="onChanged"
                  flat
                  solo
                  hide-details
                  label="Search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedArticleTypeId"
                  class="border-secondary"
                  :items="articleTypes"
                  @change="onChanged"
                  flat
                  solo
                  hide-details
                  item-text="name"
                  item-value="id"
                  height="32px"
                  label="タイプ"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selectedArticleTagIds"
                  class="border-secondary"
                  :items="articleTags"
                  @change="onChanged"
                  flat
                  solo
                  hide-details
                  item-text="name"
                  item-value="id"
                  height="32px"
                  attach
                  chips
                  label="タグ"
                  multiple
                ></v-select>
              </v-col>
            </v-row>
          </div>
          <!-- 記事一覧 -->
          <LoadingView v-show="isLoading" />
          <v-scroll-y-transition>
            <v-row class="mt-3" v-if="!isLoading">
              <v-col cols="12">
                <v-card class="d-inline-block" width="100%" outlined tile>
                  <div
                    v-for="article in filterArticles"
                    v-bind:key="article.id"
                    class="scroll_content"
                  >
                    <ArticleCard :article="article" />
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-scroll-y-transition>
        </v-container>
      </v-fade-transition>
    </v-sheet>
  </div>
</template>

<script async>
import Axios from "../repository/Axios";
import "../packs/extensions";
const ArticleCard = () =>
  import("../fuwa-component/article-card/article-card.vue");
const LoadingView = () => import("../fuwa-component/loading-view.vue");

export default {
  components: {
    ArticleCard,
    LoadingView,
  },
  data: function () {
    return {
      isShow: false,
      isLoading: true,
      searchText: "",
      articles: [],
      articleTypes: [],
      articleTags: [],
      selectedArticleTypeId: null,
      selectedArticleTypeName: null,
      articleCount: null,
      selectedArticleTagIds: [],
    };
  },
  mounted() {
    this.isShow = true;
    this.fetchArticleList();
    this.fetchArticleType();
    this.fetchArticleTag();
    this.setupQuery();
    window.addEventListener("popstate", this.setupQuery);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.setupQuery);
  },
  methods: {
    setupQuery: function () {
      if (this.$route.query.keyword) {
        this.searchText = this.$route.query.keyword.toString();
      } else {
        this.searchText = "";
      }
      if (this.$route.query.type_id) {
        this.selectedArticleTypeId = Number(this.$route.query.type_id);
      } else {
        this.selectedArticleTypeId = null;
      }
      if (this.$route.query.tag_id) {
        const tagIds = Array.isArray(this.$route.query.tag_id)
          ? this.$route.query.tag_id
          : this.$route.query.tag_id.split(",");
        this.selectedArticleTagIds = tagIds.flatMap((x) => Number(x));
      } else {
        this.selectedArticleTagIds = [];
      }
    },
    fetchArticleList: async function () {
      const { data } = await Axios.fetchArticleList(this.$root);
      this.articles = data.articles;
      this.articleCount = "（計" + data.articles.length + "件）";
      this.isLoading = false;
    },
    fetchArticleType: async function () {
      const typeData = await Axios.fetchArticleType();
      this.articleTypes = [{ id: null, name: "タイプなし" }].concat(
        typeData.data.article_types.flatMap((x) => {
          return { id: x.id, name: x.name };
        })
      );
    },
    fetchArticleTag: async function () {
      const tagData = await Axios.fetchArticleTag();
      this.articleTags = tagData.data.article_tags;
    },
    onChanged() {
      let queryData = {};
      if (this.searchText) {
        queryData.keyword = this.searchText;
      }
      if (this.selectedArticleTypeId) {
        queryData.type_id = this.selectedArticleTypeId;
      }
      queryData.tag_id = this.selectedArticleTagIds;
      this.$router.push({ query: queryData }).catch(() => {});
    },
  },
  computed: {
    filterArticles() {
      // タイトル変更
      if (this.selectedArticleTypeId != null) {
        const type = this.articleTypes.find(
          (v) => v.id === this.selectedArticleTypeId
        );
        this.selectedArticleTypeName = type != null ? type.name : null;
      } else {
        this.selectedArticleTypeName = null;
      }
      // タグ絞り込み用: 複数の特定要素のうちひとつでも当てはまったら true を返す
      const isIncludes = (arr, target) => arr.some((el) => target.includes(el));
      return this.articles
        .filter(
          // キーワード絞り込み
          (article) =>
            article.title
              .toLowerCase()
              .katakanaToHiragana()
              .includes(this.searchText.toLowerCase().katakanaToHiragana()) ||
            article.text
              .toLowerCase()
              .katakanaToHiragana()
              .includes(this.searchText.toLowerCase().katakanaToHiragana())
        )
        .filter((article) =>
          // タイプ絞り込み
          this.selectedArticleTypeId != null
            ? article.type_id == this.selectedArticleTypeId
            : true
        )
        .filter((article) =>
          // タグ絞り込み
          this.selectedArticleTagIds.length > 0
            ? isIncludes(
                article.tag.flatMap((x) => x.id),
                this.selectedArticleTagIds
              )
            : true
        );
    },
  },
};
</script>
