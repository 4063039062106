import { render, staticRenderFns } from "./want-notice.vue?vue&type=template&id=2e839cc4"
import script from "./want-notice.vue?vue&type=script&async=true&lang=js"
export * from "./want-notice.vue?vue&type=script&async=true&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports