<template>
  <v-app id="app">
    <v-container class="px-0 py-0" fluid>
      <Header />
      <div class="container-view">
        <router-view />
      </div>
      <Footer />
    </v-container>
  </v-app>
</template>

<script async>
const Header = () => import("./fuwa-component/fuwa-app/app-header.vue");
const Footer = () => import("./fuwa-component/fuwa-app/app-footer.vue");

export default {
  components: {
    Header,
    Footer,
  },
};
</script>
