<template>
  <v-sheet class="container-view">
    <v-fade-transition>
      <v-container class="py-8" v-if="isShow">
        <div class="fuwa-title mt-2 mb-2">プロダクト</div>
        <div v-for="product in products" v-bind:key="product.id">
          <ProductItem :product="product" />
        </div>
        <div class="fuwa-title mt-6 mb-2">サイト</div>
        <div v-for="site in sites" v-bind:key="site.id">
          <ProductSiteItem :site="site" />
        </div>
        <div class="fuwa-title mt-6 mb-2">ツール</div>
        <div v-for="tool in productTools" v-bind:key="tool.id">
          <ProductToolItem :tool="tool" />
        </div>
      </v-container>
    </v-fade-transition>
  </v-sheet>
</template>

<script async>
import Constants from "../packs/constants";
const ProductItem = () => import("../fuwa-component/product/product-item.vue");
const ProductSiteItem = () =>
  import("../fuwa-component/product/product-site-item.vue");
const ProductToolItem = () =>
  import("../fuwa-component/product/product-tool-item.vue");

export default {
  components: {
    ProductItem,
    ProductSiteItem,
    ProductToolItem,
  },
  data: function () {
    return {
      isShow: false,
      products: Constants.PRODUCTS,
      sites: Constants.SITES,
      productTools: Constants.PRODUCT_TOOLS,
    };
  },
  mounted() {
    this.isShow = true;
  },
};
</script>
