import axios from "axios";
import Constants from "../packs/constants";

const basePath = `/api`;

// デフォルトでAuthrization TokenをaxiosのHeaderに付与
axios.defaults.headers.common["Authorization"] = Constants.HEADER_TOKEN;
// CSRF対策
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["X-CSRF-TOKEN"] = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

export default axios.create({
  basePath,
});
