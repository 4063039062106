import Repository from "./Repository";

const resource = "/axios";

export default {
  fetchHome(root) {
    if (root.homeFetchData != null) {
      return root.homeFetchData;
    } else {
      const data = Repository.get(`${resource}/home`);
      root.homeFetchData = data;
      return data;
    }
  },
  fetchArticle(articleId) {
    return Repository.get(`${resource}/article?id=${articleId}`);
  },
  fetchArticleList(root) {
    if (root.articleListFetchData != null) {
      return root.articleListFetchData;
    } else {
      const data = Repository.get(`${resource}/article/list`);
      root.articleListFetchData = data;
      return data;
    }
  },
  fetchArticleTag() {
    return Repository.get(`${resource}/article_tag`);
  },
  fetchArticleType() {
    return Repository.get(`${resource}/article_type`);
  },
  postArticle(data) {
    return Repository.post(`${resource}/article`, data);
  },
  putArticle(data) {
    return Repository.put(`${resource}/article`, data);
  },
};
